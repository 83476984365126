<template>
  <component
    :is=" isLibraryToShow ? 'LibraryCollectionsWrapper' : 'CollectionsWrapper'"
    v-if="activeHeader || useLazyLoading"
    :render-sharing-in-header="!isLibraryToShow"
    :sections-order="sectionsOrder"
    is-store-product-preload
    v-bind="allProps"
    @saveNewItem="saveNewCollection"
    @updateCollectionOrLibrary="updateCollection"
    @changeExpandSection="changeExpandSection"
    @changeReorder="changeReorder"
    @set-new-product-image="setNewProductImage" />
</template>
<script>
import { getFullLinkForWorkspaces } from '@/utils';
import CollectionsApi from '@/services/graphql/collections';
import { ROUTE_COLLECTIONS } from '@/constants';
import {
  mapGetters, mapState, mapMutations, mapActions,
} from 'vuex';
import { COLLECTION_SORT_ITEMS } from '@/constants/sortable';
import CollectionsWrapper from '@/views/CollectionsWrapper';
import LibraryCollectionsWrapper from '@/views/LibraryCollectionsWrapper';
import getInfo from '@/mixins/getInfo';
import NavigateToCollection from '@/mixins/NavigateToCollection';
import { omit } from 'lodash';
export default {
  name: 'MyCollections',
  components: {
    LibraryCollectionsWrapper,
    CollectionsWrapper,
  },
  mixins: [
    getInfo,
    NavigateToCollection,
  ],
  data() {
    return {
      subscribeCounter: null,
      COLLECTION_SORT_ITEMS,
    };
  },
  computed: {
    ...mapGetters({
      getCollectionsGroups: 'Collections/getAppropriateCollectionsGroupsByCriteria',
      getDefaultLibraryId: 'Libraries/getDefaultLibraryId',
    }),
    ...mapGetters('FeatureFlags', [
      'mobileViewOptimizationsForTradeShow',
      'useLazyLoading',
    ]),
    ...mapGetters('Collections', ['getSortedItem']),
    ...mapState(['updateLibraryOrCollection']),
    ...mapState('Workspace', ['activeWorkspaceId']),
    ...mapState('CollapseSections', ['sectionsOrder']),
    ...mapState('UserProfile', ['userData']),
    paramLibraryId() {
      const { id } = this.$route.params || {
      };
      return id;
    },
    filteredListOfCollections() {
      const { getCollectionsGroups } = this;
      return getCollectionsGroups({
        routeName: ROUTE_COLLECTIONS,
      });
    },
    isLibraryToShow() {
      if (!this.activeHeader) {
        return false;
      }
      const { docType, kind } = this.activeHeader;
      return !(docType === 'library' && kind === 'default');
    },
    renderActiveTab() {
      if (this.paramLibraryId != this.getDefaultLibraryId) {
        return getFullLinkForWorkspaces('libraries');
      }
      return getFullLinkForWorkspaces(`collections/${this.getDefaultLibraryId}`);
    },
    allProps() {
      return {
        filteredListOfCollections: this.filteredListOfCollections,
        defaultItem: this.getSortedItem,
        sortItems: COLLECTION_SORT_ITEMS,
        activeTab: this.renderActiveTab,
        activeHeader: this.activeHeader,
        isLibraryToShow: this.isLibraryToShow,
      };
    },
  },
  beforeDestroy() {
    this.subscribeCounter?.unsubscribe();
  },
  created() {
    this.updatingCounter();
  },
  methods: {
    ...mapMutations(['spinner']),
    ...mapActions({
      getInfo: 'getInfo',
      addNewCollection: 'Collections/addNewCollection',
      handleError: 'handleError',
      updateCollectionName: 'Collections/updateCollectionName',
      getLibrariesList: 'Libraries/getLibrariesList',
      setExpandedSection: 'CollapseSections/setExpandedSection',
      setReorderSections: 'CollapseSections/setReorderSections',
    }),
    async changeExpandSection(section) {
      const { paramLibraryId: libraryId } = this;
      await this.setExpandedSection({
        section, libraryId,
      });
    },
    async changeReorder(sectionOrder) {
      const { paramLibraryId: libraryId } = this;
      await this.setReorderSections({
        libraryId,
        sectionOrder,
      });
    },
    getFullLinkForWorkspaces,
    renderAddress(info) {
      const { location, address } = info;
      const addressObject = {
        location,
        address,
      };
      const paramToOmit = address ? 'location' : 'address';
      return omit(addressObject, [paramToOmit]);
    },
    // listen to change in product count in a collection
    updatingCounter() {
      const { handleError } = this;
      try {
        this.subscribeCounter = CollectionsApi.subscribeProductsCountChange().subscribe({
          next: ({ value }) => {
            const { id: collectionId, productsCount } = value.data.response;
            const collectionInfo = this.filteredListOfCollections.find(el => this.collectionList(el.list).some(col => col.id === collectionId));
            if (collectionInfo?.list) {
              const updateCounter = this.collectionList(collectionInfo.list).map(el => (
                el.id === collectionId ? {
                  ...el,
                  productsCount,
                } : el
              ));
              this.$store.commit(`Collections/${collectionInfo.mutationName}`, updateCounter);
            }
          },
        });
      } catch (err) {
        handleError(err);
      }
    },
    collectionList(list) {
      return this.$store.state.Collections[list];
    },
    async addToLibrary(info) {
      this.spinner(true);
      try {
        const workspaceId = this.activeWorkspaceId;
        await CollectionsApi.addCollectionToLibrary({
          ...info,
          workspaceId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    async saveNewCollection(items) {
      const { name } = items;
      this.spinner(true);
      try {
        const { kind, libraryId, id } = this.activeHeader;
        const workspaceId = this.activeWorkspaceId;
        const create = 'createV3';
        const { data } = await CollectionsApi[create]({
          name,
          libraryId: libraryId ? libraryId : id,
          workspaceId,
        });
        this.addNewCollection({
          ...data.response,
          kind: kind === 'custom' ? 'custom' : null,
        });
        const { id: collectionId } = data?.response;
        if (kind === 'custom') {
          this.addToLibrary({
            collectionId,
            libraryIds: [this.paramLibraryId],
          });
        }
        const { mobileViewOptimizationsForTradeShow: flag } = this;
        if (!flag) return;
        // method in NavigateToCollection mixin
        this.navigateToCollection({
          id: collectionId,
        });
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
    setNewPictures(pictures, image) {
      if (pictures.length && (pictures.length >= 3 || !pictures[0]?.attachment?.id)) {
        return pictures;
      } else {
        return [
          ...pictures,
          {
            attachment: image,
          },
        ];
      }
    },
    setNewProductImage(data) {
      const { image, collectionId } = data;
      const collectionInfo = this.filteredListOfCollections.find(el => this.collectionList(el.list).some(col => col.id === collectionId));
      if (!collectionInfo) return false;
      const newListWithImage = this.collectionList(collectionInfo.list).map(el => (
        el.id === collectionId ? {
          ...el,
          pictures: this.setNewPictures(el.pictures, image),
        } : el
      ));
      this.$store.commit(`Collections/${collectionInfo.mutationName}`, newListWithImage);
    },
    async updateCollection(items) {
      this.spinner(true);
      const { name, description, logo, author } = items;
      const { libraryId, id } = this.updateLibraryOrCollection;
      try {
        const data = await CollectionsApi.update({
          libraryId,
          collectionId: id,
          name,
          description,
          logo,
          author,
          workspaceId: this.activeWorkspaceId,
          ...this.renderAddress(items),
        });
        this.updateCollectionName(data.data.response);
      } catch (err) {
        this.handleError(err);
      } finally {
        this.spinner(false);
      }
    },
  },
};
</script>
