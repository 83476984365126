import { getFullLinkForWorkspaces } from '@/utils';
import { mapGetters } from 'vuex';
import SearchCollections from '@/services/graphql/searching/searchCollections';
import SearchProducts from '@/services/graphql/searching/searchProducts';
import SearchCollectionsAndProducts
  from '@/services/graphql/searching/searchCollectionsAndProducts';
import {
  SEARCH_COLLECTIONS_V3, SEARCH_PRODUCTS_V3,
} from '@/constants/searching/searchingAsyncV2';
export default {
  props: {
    filteredListOfCollections: {
      type: Array,
      default: () => [],
    },
    defaultItem: {
      type: Object,
      default: null,
    },
    sortItems: {
      type: Array,
      default: () => [],
      required: true,
    },
    activeTab: {
      type: String,
      default: getFullLinkForWorkspaces(),
    },
    activeHeader: {
      type: Object,
      default: () => null,
    },
    isLibraryToShow: {
      type: Boolean,
      default: true,
    },
    isStoreProductPreload: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      getDefaultLibraryId: 'Libraries/getDefaultLibraryId',
    }),
    propsDataForSearching() {
      const arrNameRequest = [SEARCH_COLLECTIONS_V3, SEARCH_PRODUCTS_V3];
      const basicSearchingRequests = {
        asyncCollectionsApiMethod: {
          request: SearchCollections['asyncCollectionsV3'],
          nameRequest: SEARCH_COLLECTIONS_V3,
          initVariables: {
            myCollections: true,
          },
        },
        asyncProductsApiMethod: {
          request: SearchProducts['asyncProductsV3'],
          nameRequest: SEARCH_PRODUCTS_V3,
          initVariables: {
            myCollections: true,
          },
        },
        asyncCollectionsAndProductsApiMethod: {
          request: SearchCollectionsAndProducts['asyncCollectionsAndProductsV3'],
          initVariables: {
            myCollections: true,
          },
        },
        arrNameRequest,
      };
      if (!this.isLibraryToShow) {
        return {
          ...basicSearchingRequests,
          searchPlaceholder: 'Search your collections',
          libraryId: this.getDefaultLibraryId,
        };
      } else {
        return {
          ...basicSearchingRequests,
          searchPlaceholder: 'Search in this Library',
          libraryId: this.currentLibraryId,
        };
      }
    },
    currentLibraryId() {
      return this.$route.params.id;
    },
  },
};
